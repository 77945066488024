import { Grid, styled, TextField } from "@mui/material";
import { EffectFilterDateField, FilterDefinition, MeasureFieldNames, validateBasicMeasureFilterAndScopeConfig } from "api-shared";
import { keyBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { useNonDeletedUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import ScopeSelect from "../../measures/preferences/ScopeSelect";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66),
}));

const StatusAggregationWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();

    const users = useNonDeletedUsers();
    const measureAttributes = useMeasureAttributes();
    const measureConfigs = useMeasureConfigs();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    if (!validateBasicMeasureFilterAndScopeConfig(config)) {
        return null;
    }

    const handleFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        setIsValidFilter(isValid);
        onConfigChange({ ...config, filter: newFilter }, isValid);
    };

    // remove `EffectFilterDateField.StartDate` and `MeasureFieldNames.Status` from possible filters because it is used by the widget itself
    //
    const filteredDefinitions =
        fieldDefinitionsQuery.data != null
            ? keyBy(
                  Object.values(fieldDefinitionsQuery.data).filter(
                      (fieldDefinition) =>
                          fieldDefinition.name !== EffectFilterDateField.StartDate && fieldDefinition.name !== MeasureFieldNames.Status,
                  ),
                  "name",
              )
            : {};

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={(e) => onNameChange(e.target.value)}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_NAME)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((mc: any) => mc.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                translate={translate}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={handleFilterChange}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                        fieldDefinitions={filteredDefinitions}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default StatusAggregationWidgetConfigForm;

import { blueGrey, grey } from "@mui/material/colors";
import { LegendLayout, LiveRunUpGranularity, LiveRunUpWidgetConfig, TooltipLayout } from "api-shared";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import { Option } from "../../../components/input/select/types";
import useCurrency from "../../../hooks/useCurrency";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";
import { DeltaLabelList } from "../charts/DeltaLabelList.tsx";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import TargetChip from "../charts/TargetChip";
import { useCurrencyYAxisProps, useDashboardColors, useLegendProps, useMonthXAxisProps, useTooltipProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { ExtendableCircleLegend } from "../reporting/ExtendableCircleLegend";
import { CalculatedStackKeys, getDifferenceToTarget } from "../utils";

interface RunUpChartProps {
    data: { [key: string]: string | number }[];
    xAxis: string;
    target?: number;
    groups: Option<string>[];
    granularity: LiveRunUpGranularity;
    onOpenDrilldown: (xValue: string, stackingValue: unknown) => void;
    showSums?: boolean;
    axisMinValue: number | null;
    axisMaxValue: number | null;
    showReferenceValues?: boolean;
    referenceValues: LiveRunUpWidgetConfig["referenceValues"];
}

const RunUpChart = (props: RunUpChartProps) => {
    const {
        data,
        xAxis,
        target,
        groups,
        granularity,
        onOpenDrilldown,
        showSums,
        axisMinValue,
        axisMaxValue,
        showReferenceValues,
        referenceValues,
    } = props;
    const colors = useDashboardColors();

    const { formatCurrency, formatCurrencyShort } = useCurrency();
    const { formatShortDateTime } = useTimezone();

    const { t: translate } = useTranslation();

    const currencyYAxisProps = useCurrencyYAxisProps(target, axisMinValue, axisMaxValue);
    const monthXAxisProps = useMonthXAxisProps();

    const renderReferenceValues = showSums && showReferenceValues && referenceValues !== null;
    const legendPropsSpacing = 2 + Number(renderReferenceValues) * 4;
    const legendProps = useLegendProps(legendPropsSpacing);
    // Pass target to tooltip in order to render the difference to target independently from its visualization in the chart
    const tooltipProps = useTooltipProps({ showDifferenceToTarget: true, target, tooltipLayout: TooltipLayout.Reversed });

    const groupsInUse = new Set<string>(data.flatMap(Object.keys));
    groupsInUse.delete(xAxis);

    const barSegments = getBarSegments(groups, colors, groupsInUse);

    const sortedData = sortBy(data, xAxis);

    const withDifferenceToTarget =
        target != null
            ? sortedData.map((item) => ({ ...item, [CalculatedStackKeys.DIFFERENCE_TO_TARGET]: getDifferenceToTarget(target, item) }))
            : sortedData;

    // For (future) formatting of half-years, see;
    // https://github.com/moment/moment/issues/3582#issuecomment-381762982
    function labelFormatter(label: string) {
        switch (granularity) {
            case LiveRunUpGranularity.Month:
                return moment(label).format("MMM YYYY");
            case LiveRunUpGranularity.Year:
                return moment(label).format("YYYY");
            case LiveRunUpGranularity.Quarter:
            default:
                return moment(label).format("[Q]Q YYYY");
        }
    }

    const handleBarClick = (payload: any, tooltipPayload: any) => {
        const stackingValue = tooltipPayload?.[0]?.dataKey;
        const xValue = payload[xAxis];
        return onOpenDrilldown(xValue, stackingValue);
    };

    const barSegmentKeys = barSegments.map((segment) => segment.key);
    return (
        <ResponsiveContainer>
            <ComposedChart data={withDifferenceToTarget} stackOffset="sign">
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <Legend
                    {...legendProps}
                    content={
                        target != null ? (
                            <ExtendableCircleLegend
                                legendLayout={LegendLayout.Reversed}
                                deltaTimestamp={renderReferenceValues ? formatShortDateTime(referenceValues.timestamp) : null}
                            >
                                <TargetChip
                                    size="small"
                                    label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_CHIP, {
                                        value: formatCurrencyShort(target),
                                    })}
                                />
                            </ExtendableCircleLegend>
                        ) : (
                            <ExtendableCircleLegend
                                legendLayout={LegendLayout.Reversed}
                                deltaTimestamp={renderReferenceValues ? formatShortDateTime(referenceValues.timestamp) : null}
                            />
                        )
                    }
                />
                <Tooltip
                    {...tooltipProps}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                    labelFormatter={labelFormatter}
                />

                {barSegments.map(({ key, color, label }, barSegmentIndex, allSegments) => (
                    <Bar
                        name={label}
                        key={key}
                        dataKey={key}
                        stackId="a" // all bars segments should be stacked in the same bar
                        fill={color}
                        stroke="white"
                        strokeWidth={1}
                        legendType="circle"
                        cursor="pointer"
                        onClick={({ payload, tooltipPayload }) => handleBarClick(payload, tooltipPayload)}
                        isAnimationActive={false}
                    >
                        {showSums ? <ShortNoCodeCurrencyLabelList stackDataKeys={barSegmentKeys} /> : null}
                        {renderReferenceValues ? (
                            <DeltaLabelList stackDataKeys={barSegmentKeys} referenceValues={referenceValues} isVerticalLayout />
                        ) : null}
                    </Bar>
                ))}
                {target != null ? (
                    <Bar
                        name={translate(translationKeys.VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL)}
                        dataKey={CalculatedStackKeys.DIFFERENCE_TO_TARGET}
                        stackId="a"
                        fill="none"
                        stroke={blueGrey[800]}
                        strokeDasharray="4 2"
                        strokeWidth={1}
                        legendType="circle"
                    />
                ) : null}
                {/* Render axes last so that other elements (e.g. bars) do not overlay them */}
                <ReferenceLine y={0} stroke={grey[600]} />
                <XAxis {...monthXAxisProps} dataKey={xAxis} tickFormatter={labelFormatter} />
                <YAxis {...currencyYAxisProps} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default RunUpChart;

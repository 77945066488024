import { styled } from "@mui/material";
import { ProcessListWidgetConfig, Sort, validateProcessListWidgetConfig } from "api-shared";
import MeasuresWidget from "../../components/measures/MeasuresWidget";
import ProcessListWidgetConfigForm from "./ProcessListWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";

const DEFAULT_PAGESIZE_OPTIONS = [25, 50, 100];

const BorderedMeasuresWidget = styled(MeasuresWidget)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const ProcessListWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const dataKey = `widget_${widget.id}`;

    const handleSortingChange = (orderBy: string, sort: Sort) => {
        onConfigSave({
            config: {
                ...(widget.config as ProcessListWidgetConfig),
                orderBy,
                sort,
            },
        });
    };

    if (!validateProcessListWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateProcessListWidgetConfig}
                FormComponent={ProcessListWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <BorderedMeasuresWidget
                isDense
                fullHeight
                dataKey={dataKey}
                filter={widget.config.filter}
                orderBy={widget.config.orderBy}
                sort={widget.config.sort}
                scope={widget.config.scope}
                onSortChanged={handleSortingChange}
                columns={widget.config.columns}
                densePagination
                pageSizeOptions={DEFAULT_PAGESIZE_OPTIONS}
                disableSortBy={disabled}
                enableQueries={isInView}
            />
        </>
    );
};

export default ProcessListWidget;

import { styled } from "@mui/material";
import { IdeaDto, IdeaListWidgetConfig, Sort, validateIdeaListWidgetConfig } from "api-shared";
import { SortingRule } from "react-table";
import { useIdeaFieldDefinitionsQuery } from "../../domain/filters";
import { useIdeaAttributesQuery, useIdeaSearchQuery } from "../../domain/ideas";
import { ApiError } from "../../lib/api";
import { getTranslatedErrorMessage } from "../../lib/error";
import { IdeaTable } from "../ideas/IdeaTable";
import IdeaListWidgetConfigForm from "./IdeaListWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";
import WidgetError from "./WidgetError";

const DEFAULT_PAGESIZE_OPTIONS = [25, 50, 100];

const WidgetIdeaTable = styled(IdeaTable)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const IdeaListWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const ideasQuery = useIdeaSearchQuery((widget.config as IdeaListWidgetConfig).filter, { ignoreErrors: true, enabled: isInView });
    const attributesQuery = useIdeaAttributesQuery(isInView);
    const fieldDefinitionsQuery = useIdeaFieldDefinitionsQuery(isInView);

    const handleSortingChange = (newSortBy: SortingRule<IdeaDto>) => {
        onConfigSave({
            config: {
                ...(widget.config as IdeaListWidgetConfig),
                orderBy: newSortBy.id,
                sort: newSortBy.desc ? Sort.DESCENDING : Sort.ASCENDING,
            },
        });
    };

    if (!validateIdeaListWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateIdeaListWidgetConfig}
                FormComponent={IdeaListWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />

            {ideasQuery.isError && ideasQuery.error instanceof ApiError ? (
                <WidgetError>{getTranslatedErrorMessage(ideasQuery.error, translate)}</WidgetError>
            ) : (
                attributesQuery.isSuccess && (
                    <WidgetIdeaTable
                        isFetching={ideasQuery.isFetching}
                        data={ideasQuery.data ?? []}
                        attributes={attributesQuery.data}
                        fieldDefinitions={fieldDefinitionsQuery.data ?? {}}
                        isDense
                        densePagination
                        columns={widget.config.columns}
                        defaultPageSize={DEFAULT_PAGESIZE_OPTIONS[0]}
                        pageSizeOptions={DEFAULT_PAGESIZE_OPTIONS}
                        sort={widget.config.sort}
                        sortBy={widget.config.orderBy}
                        onSortByChanged={handleSortingChange}
                    />
                )
            )}
        </>
    );
};

export default IdeaListWidget;

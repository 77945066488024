import { Grid, styled, TextField } from "@mui/material";
import { FilterDefinition, MeasureFieldNames, validateProcessListWidgetConfig } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import TableColumnConfigForm from "../../components/table/TableColumnConfigForm";
import { useClientFiscalYear, useCurrentClient } from "../../domain/client";
import { useMeasureAttributes } from "../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../domain/filters";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useNonDeletedUsers } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import FilterForm from "../measures/preferences/filter-configuration/FilterForm";
import ScopeSelect from "../measures/preferences/ScopeSelect";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";
import WidgetDescriptionField from "./WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66), // fix height across all tabs, the maximum height is dicated by the ColumnConfigForm component
}));

const ProcessListWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();
    const users = useNonDeletedUsers();
    const measureAttributes = useMeasureAttributes();
    const measureConfigs = useMeasureConfigs();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();
    if (!validateProcessListWidgetConfig(config)) {
        return null;
    }
    // from here, config's type is narrowed down to ProcessListWidgetConfig

    const handleColumnsChange = (newColumns: string[]): void => {
        let newOrderBy: string | undefined = undefined;
        if (!newColumns.includes(config.orderBy)) {
            // Column after which was ordered is not visible anymore. Fall back to the first visible and sortable one
            // If none of the columns is sortable, fall back to the title. Actually this should not happen, as the title column cannot be
            // removed, but play it safe here
            newOrderBy = newColumns.find((newColumn) => fieldDefinitionsQuery.data?.[newColumn]?.isSortable) ?? MeasureFieldNames.Title;
        }
        onConfigChange(
            {
                ...config,
                columns: newColumns,
                ...(newOrderBy && { orderBy: newOrderBy }),
            },
            isValidFilter,
        );
    };

    const handleFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        setIsValidFilter(isValid);
        onConfigChange({ ...config, filter: newFilter }, isValid);
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_COLUMN_CONFIGURATION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} label="Name" disabled={disabled} />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <TableColumnConfigForm
                        columns={config.columns}
                        disabledColumns={[MeasureFieldNames.Title]}
                        updateColumns={handleColumnsChange}
                        translate={translate}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((mc: any) => mc.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                translate={translate}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 4 ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={handleFilterChange}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default ProcessListWidgetConfigForm;
